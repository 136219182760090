.info {
    display: flex;
    padding: 100px 20px;
    justify-content: center;
    gap: 80px;
}

.photo_and_p {
    width: 400px;
    display: flex;
    flex-direction: column;
}

.info_content {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.h1_heading {
    margin:0;
    width: 100%;
    font-size: 42px;
    font-weight: 400;
}

.info img {
    width: auto;
}

.stickers_paragraph {
    font-size: 16px;
    font-weight: 300;
    margin-top: 50px;
}

.price_p {
    font-size: 20px;
}

.qnt > input[type=number] {
    width: 40px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
}

.add_to_cart {
    background-color: #45A37A;
    color: white;
    text-align: center;
    border: none;
    font-family: "Work Sans", serif;
    padding: 10px 0;
    font-size: 16px;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

.qnt {
    display: flex;
    gap: 10px;
}

.pcs{
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    color: gray;
}