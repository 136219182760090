.socks {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
}

.socks_list {
    display: flex;
    flex-wrap: wrap;
    gap: 130px;
    justify-content: center;
    max-width: 90%;
}

.socks_heading {
    font-size: 51px;
    width: 100%;
    text-align: center;
    font-weight: 300;
    padding: 50px 0;
    background: #F7F5F4;
}

.sock_heading {
    margin: 0;
    width: 300px;
}