.cart-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart-item {
    padding: 10px;
    border-radius: 10px;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    margin: 10px;
    background-color: white;
}

.cart-item img {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

.cart-item-details > p, button {
    margin: 0;
    font-weight: 300;
}

.cart-item-details > h2 {
    margin: 0;
    font-weight: 500;
    background-color: #F7F5F4;
}

.remove_button {
    border: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
    background-color: #d72424;
    font-family: "Work Sans", serif;
}

.cart-item-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.cart-container h1 {
    display: flex;
    justify-content: center;
    font-weight: 300;
    padding: 30px 0;
    background-color: white;
    margin: 0;
}

.empty {
    text-align: center;
    padding-top: 50px;
}

.cart-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: white;
    width: 100%;
}

.cart-summary h2, p {
    margin: 0;
}