.sock_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.sock_container img {
    width:245px;
    height:245px;
    object-fit: cover;
}

.sock_heading {
    color: black;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}

.black_line {
    height: 2px;
    width: 40px;
    background-color: black;
}

.price {
    color: gray;
}

