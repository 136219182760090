.about_text{
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    color: #665D5B;
    max-width: 600px;
    margin: 50px 0;
}
.about_page {
    display: flex;
    justify-content: center;
}

.link_location, .link_site_1, .link_site_2 {
    font-weight: 400;
    color: #665D5B;
    text-decoration: underline;
}