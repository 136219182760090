.line {
    display: flex;
    width: 100%;
    background: #F7F5F4;
    height: 62px;
}

.footer {
    display: flex;
    background: white;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.column_1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
}

.customer_heading {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
}

.customer_a {
    color: black;
    font-weight: 300;
}

.content {
    display: flex;
    gap: 50px;
    padding: 30px 0;
}

.logo_footer {
    width: 212px;
    height: auto;
}

.icon_footer img {
    width: 32px;
    height: auto;
}

.column_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.logos {
    display: flex;
    gap: 10px;
}