.shop {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shop_heading {
    width: 100%;
    font-size: 54px;
    color: black;
    background-color: #F7F5F4;
    text-align: center;
    font-weight: 300;
}

.categories {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    padding: 10px 0px 200px 0px;
    gap: 20px;
}

.first_c, .second_c, .third_c{
    width: 197px;
    height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 22px;
    font-weight: 300;
}

.first_c:hover {
    background-color: white;
    color: #CCBAB6;
    border: 1px solid #CCBAB6;
    transition: 0.2s ease-in-out;
}

.second_c:hover {
    background-color: white;
    color: #665D5B;
    border: 1px solid #665D5B;
    transition: 0.2s ease-in-out;
}

.third_c:hover{
    background-color: white;
    color: #998C88;
    border: 1px solid #998C88;
    transition: 0.2s ease-in-out;
}

.first_c {
    background-color: #CCBAB6;
}

.second_c {
    background-color: #665D5B;
}

.third_c {
    background-color: #998C88;
}
