.full_header {
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
}

.header {
    display: flex;
    flex-direction: column;
    background-image: url("../images/background.jpg");
    height: 50vh;
    background-size: cover;
    background-position: bottom;
}

.svg_cart {
    position: absolute;
    width: 28px;
    height: 35px;
    fill: white;
    top: 10px;
    right: 13%;
}

nav {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 34px;
    margin-top: 19vh;
}

nav h1 {
    margin: 0;
    font-weight: 100;
}

nav a {
    text-decoration: none;
    color: #998C88;
    font-weight: 300;
}

.main_elements {
    display: flex;
    position: absolute;
    margin-top: 40vh;
    gap: 80px;
    left: 50%;
    transform: translate(-60%, -40%);
}

.sheep_a {
    object-fit: cover;
}