.mission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #F7F5F4, white);
    padding: 50px 0;
}

.mission_heading {
    text-align: center;
    font-size: 106px;
    font-weight: 200;
    margin: 0;
    color: #665D5B;
}

.mission_p {
    text-align: center;
    font-size: 25px;
    font-weight: 200;
    color: #665D5B;
}

.shop_now {
    font-size: 25px;
    font-weight: 300;
    text-decoration: none;
    border: 1px solid #626262;
    color: #626262;
    padding: 10px 50px;
    margin-top: 40px;
}

.instagram {
    margin-top: 20px;
    width: 128px;
    height: 128px;
}
